const languageTag = {
  en: 'en-EN',
  fr: 'fr-FR',
}

// Workaround found here https://github.com/aralroca/next-translate/issues/851#issuecomment-1173611946
//  to import dynamically the locales from a custom path
// const nextTranslateConfig = require('next-translate/lib/cjs/plugin/utils.js')
// nextTranslateConfig.defaultLoader =
//   '(l, n) => import(`locales/${l}/${n}.json`).then(m => m.default)'

module.exports = {
  locales: ['default', 'en', 'fr'],
  defaultLocale: 'default',
  pages: {
    '*': ['common'],
  },
  interpolation: {
    prefix: '${',
    suffix: '}',
    format: (value, format, lng) => {
      if (format === 'number') {
        return new Intl.NumberFormat(languageTag[lng]).format(value)
      }

      if (format === 'price') {
        if (!value.price || !value.currency)
          throw new Error(
            '`value` must be a `{ price, currency }` object when using price formatter',
          )

        const { price, currency } = value

        return new Intl.NumberFormat(languageTag[lng], {
          style: 'currency',
          currency,
        }).format(price)
      }

      if (value instanceof Date && !isNaN(value.valueOf())) {
        if (format === 'short') return value.toLocaleDateString(lng)
        if (format === 'medium')
          return value.toLocaleDateString(lng, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        if (format === 'long')
          return value.toLocaleDateString(lng, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        if (format === 'relative') return value.toLocaleDateString(lng)
        if (format === 'ago') return value.toLocaleDateString(lng)

        return value.toLocaleDateString(lng)
      }

      return value
    },
  },
  loadLocaleFrom: (locale, namespace) =>
    import(`locales/${locale}/${namespace}`).then((m) => m.default),
}
