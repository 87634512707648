import { datadogLogs } from '@datadog/browser-logs'

export const initDatadog = (clientToken: string) => {
  datadogLogs.init({
    clientToken: clientToken,
    site: process.env['NEXT_PUBLIC_DATADOG_SITE'],
    forwardErrorsToLogs: true,
    env: process.env['NEXT_PUBLIC_VERCEL_ENV'],
    service: process.env['NEXT_PUBLIC_DATADOG_SERVICE'],
    version: process.env['NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA'],
  })
}
